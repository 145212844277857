import { kran_opt, kran_opt_special, optionsByDia, Table3, optionsByDia3, typeTableByCount } from "./Data";
import { createHashHistory } from 'history';
import { encode, decode } from 'js-base64';
import isarray from "isarray";

export const history = createHashHistory();

const initObj = {
  count: undefined,
  trubo: 1,
  type: undefined,
  dia: '',
  volume: '',
  additional_du: false,
  interf: 'RS485',
  temp: '5',
  limit: '',
  t3min: undefined,
  mindiff: '0,5',
  massmethod: '0',
  energymethod: '0',
  preobr: 'нет',
  tempizm: '105',
  metal: false,
  lenv: '0,3',
  lenobr: '1,5',
  s_nom: '',
  s_pok: '',
  s_dat: new Date().toLocaleDateString(),
  obr: false,
  obr2: false,
  obr3: false,
  lenv2: '0,3',
  lenv3: '0,3',
  s_tel: '',
  s_mail: '',
  s_city: '',
  s_activity: '', // Сфера деятельности компании
  s_address: '', // Адрес проектной организации (если проектная компания)
  zima: false,
  zima_month: '10',
  zima_day: '15',
  leto_month: '4',
  leto_day: '15',
  temp_leto: '5',
  //   na_objekte: false, // Возможность конфигурирования на объекте
  s_obj: '',
  s_firm: '',
  s_inn: '',
  s_comment: '',
  quantity: 1,
  table_kran: [],
  table_rezba: [],
  table_privarka: [],
  table_radiator: []
}

// 0 - Boolean
// 1 - float
// 3 - JSON
// 2 - everything else
const params = {
  "additional_du": 0,
  "count": 1,
  "dia": 2,
  "dia2": 2,
  "dia3": 2,
  "dop1": 2,
  "dop2": 2,
  "dop3": 2,
  "dop4": 2,
  "dop5": 2,
  "dop6": 2,
  "dop7": 2,
  "dop8": 2,
  "dop9": 2,
  "dopkey": 2,
  "energymethod": 2,
  "interf": 2,
  "lenpreobr": 2,
  "lenv": 2,
  // "limit":1,
  "massmethod": 2,
  "mindiff": 2,
  "preobr": 2,
  "temp": 2,
  "tempizm": 2,
  "t3min": 1,
  "metal": 0,
  "trubo": 1,
  "type": 2,
  "volume": 1,
  "volume2": 1,
  "volume3": 1,
  'limit': 1, // предельный объемный расход
  "obr": 0,
  "obr2": 0,
  "obr3": 0,
  "lenobr": 2,
  "s_nom": 2,
  "s_pok": 2,
  "s_dat": 2,
  "lenv2": 2,
  "lenv3": 2,
  "s_tel": 2,
  "s_mail": 2,
  "s_city": 2,
  "s_activity": 2, // Сфера деятельности компании
  "s_address": 2, // Адрес проектной организации (если проектная компания)
  "zima": 0,
  "zima_month": 2,
  "zima_day": 2,
  "leto_month": 2,
  "leto_day": 2,
  "temp_leto": 2,
  //   "na_objekte": 0, // Возможность конфигурирования на объекте
  "s_obj": 2,
  "s_firm": 2,
  "s_inn": 2,
  "s_comment": 2,
  "quantity": 1,
  "table_kran": 3,
  "table_rezba": 3,
  "table_privarka": 3,
  "table_radiator": 3,

};


export function hashToObject(hash) {
  try {
    if (hash) {
      const hashArr = decode(hash.replace('_', '/')).split('*');
      let obj = {};
      const keys = Object.keys(params);
      if (keys.length !== 56) {
        return initObj;
      }
      hashArr.forEach((p, i) => {
        obj[keys[i]] =
          params[keys[i]] === 0 ? (p === '' ? false : p === '1') :
            params[keys[i]] === 1 ? (p === '' ? undefined : parseFloat(p)) :
              params[keys[i]] === 3 ? (p === '' ? undefined : JSON.parse(p)) :
                p;
      });
      return obj;
    } else
      return initObj;
  } catch (err) {
    console.warn(err);
    return initObj;
  }
}

export function objectToHash(object) {
  if (object) {
    const keys = Object.keys(params);
    const arr = keys.reduce((acc, p, i) => {
      let zn;
      if (object[keys[i]] === undefined || object[keys[i]] === null)
        zn = '';
      else
        if (params[keys[i]] === 0)
          zn = object[keys[i]] === true ? '1' : object[keys[i]] === false ? '0' : '';
        else
          if (params[keys[i]] === 3)
            zn = JSON.stringify(object[keys[i]]);
          else
            zn = object[keys[i]].toString();
      acc.push(zn);
      return acc;
    }, []);
    return encode(arr.join('*')).replace('/', '_');
  }
  else
    return '';
}
function testKey(new_state, old_state) {
  const findKeyNeeded = (state) => state?.table_kran?.some(el => [40, 100, 200].includes(parseInt(el.du)) || false)
  const newStateKeyNeeded = findKeyNeeded(new_state);
  const oldStateKeyNeeded = findKeyNeeded(old_state);
  if (newStateKeyNeeded !== oldStateKeyNeeded) {
    return { ...new_state, dopkey: newStateKeyNeeded ? 1 : 0 };
  }
  return new_state;
}

function _needSleeve(du) {
  return du >= 25
}

function innerReducer(state, action) {
  switch (action.type) {
    case 'QUANTITY':
      // update quantity for sleeves (гильза) in table for Арматура для термопреобразователя в обратный трубопровод
      state["table_kran"].forEach(row => {
        if (_needSleeve(row.du)) {
          row.quantity = +action.id
        }
      })
      return {
        ...state,
        quantity: action.id
      }
    case 'COUNT':
      return {
        ...state,
        count: action.id,
        type: undefined,
        trubo: state.type === "04" && action.id === 1 ? 3 : undefined,
        energymethod: '0',
        massmethod: '0',
        obr2: false,
      };
    case 'DIA':
      const du = parseInt(action.id) >= 80 ?
        80 :
        kran_opt?.find((el) => el?.dia?.some(r => r === parseInt(action.id)))?.id;
      const quantity = _needSleeve(du) ? state.quantity : 1
      const vol = optionsByDia[action.id] ? optionsByDia[action.id][0] : ''
      return {
        ...state,
        dia: action.id,
        volume: vol,
        // limit: Table2.find( (el) => el.du === parseInt(action.id) && el.maxim === parseFloat(vol)).limit,
        additional_du: false,
        table_kran: du ? [{ du, quantity }] : [],

      };
    case 'ENERGYMETHOD':
      return { ...state, energymethod: action.id, obr2: false, };
    case 'DIA2':
      return { ...state, dia2: action.id, volume2: optionsByDia[action.id] ? optionsByDia[action.id][0] : '' };
    case 'DIA3':
      let vol3 = optionsByDia3[action.id] ? optionsByDia3[action.id][0] : ''
      return { ...state, dia3: action.id, volume3: vol3, t3min: Table3.find((el) => el.du === parseInt(action.id) && el.nom === parseFloat(vol3)).minim };
    case 'VOLUME':
      return {
        ...state,
        volume: action.id,
        // limit: Table2.find( (el) => el.du === parseInt(state.dia) && el.maxim === parseFloat(action.id)).limit,
      }
    case 'LIMIT':
      return {
        ...state, limit: action.id
      }
    case 'TABLE_DROP':
      return { ...state, [action.table]: [] };
    case 'TABLE_SET':
      let table = JSON.parse(JSON.stringify(state[action.table] || []));
      let row = table[action.row] || {};

      const getQuantity = (action, row) => {
        if (action.table === "table_kran" && _needSleeve(action.du)) {
          return state.quantity
        }
        else if (action.quantity !== undefined) {
          return action.quantity
        }
        else if (row.quantity && row.quantity !== state.quantity) {
          return row.quantity
        }
        else {
          return 1
        }
      }

      row = { ...row, du: action.du !== undefined ? action.du : row.du, quantity: getQuantity(action, row) };
      if (!(row.du) || !(row.quantity))
        table.splice(action.row, 1)
      else
        table[action.row] = row;

      table = table.filter((el, rowNum) => table.filter((m, r) => m.du === el.du && r > rowNum).length === 0)

      return { ...state, [action.table]: table }
    case 'S_ACTIVITY':
      return { ...state, s_activity: action.id, s_address: '' }
    case 'TYPE':
      return {
        ...state,
        type: action.id,
        energymethod: '0',
        obr2: false,
        massmethod: '0',
        trubo: action.id === "04" ? 3 : state.trubo
      }
    default:
      let newStat = { ...state };
      if (action.type)
        newStat[('' + action.type).toLowerCase()] = action.id;
      if (action.type === 'OBR' || action.type === 'PREOBR') {
        if (newStat.obr && newStat.preobr !== 'нет') {
          newStat.preobr = 'нет';
        }
      }
      return newStat;
  }
}

function _writeToLocalStorage(key, value) {
  if (!value) {
    localStorage.removeItem(key)
  }
  else {
    localStorage.setItem(key, value)
  }
}

export function getReducer() {
  return (state, action) => {
    const newState = testKey(innerReducer(state, action), state);
    if (action.type === 'S_POK') { _writeToLocalStorage('s_pok', newState.s_pok); }
    if (action.type === 'S_TEL') { _writeToLocalStorage('s_tel', newState.s_tel); }
    if (action.type === 'S_OBJ') { _writeToLocalStorage('s_obj', newState.s_obj); }
    if (action.type === 'S_FIRM') { _writeToLocalStorage('s_firm', newState.s_firm); }
    if (action.type === 'S_INN') { _writeToLocalStorage('s_inn', newState.s_inn); }
    if (action.type === 'S_MAIL') { _writeToLocalStorage('s_mail', newState.s_mail); }
    if (action.type === 'S_CITY') { _writeToLocalStorage('s_city', newState.s_city); }
    if (action.type === 'S_ACTIVITY') { _writeToLocalStorage('s_activity', newState.s_activity); }
    if (action.type === 'S_ADDRESS') { _writeToLocalStorage('s_address', newState.s_address); }
    window.location.hash = '#/' + objectToHash(newState);
    return newState;
  }
}

export function initReducer() {
  let obj = initObj;
  if (history.location.pathname && history.location.pathname !== '/')
    obj = hashToObject(history.location.pathname.replace(/^\//, ""));

  obj.quantity = obj.quantity || 1;

  obj.s_pok = obj.s_pok || localStorage.getItem('s_pok') || "";
  obj.s_tel = obj.s_tel || localStorage.getItem('s_tel') || "";
  obj.s_obj = obj.s_obj || localStorage.getItem('s_obj') || "";
  obj.s_firm = obj.s_firm || localStorage.getItem('s_firm') || "";
  obj.s_inn = obj.s_inn || localStorage.getItem('s_inn') || "";
  obj.s_mail = obj.s_mail || localStorage.getItem('s_mail') || "";
  obj.s_city = obj.s_city || localStorage.getItem('s_city') || "";
  obj.s_activity = obj.s_activity || localStorage.getItem('s_activity') || "";
  obj.s_address = obj.s_address || localStorage.getItem('s_address') || "";
  if (!isarray(obj.table_kran)) obj.table_kran = [];
  if (!isarray(obj.table_rezba)) obj.table_rezba = [];
  if (!isarray(obj.table_privarka)) obj.table_privarka = [];
  if (!isarray(obj.table_radiator)) obj.table_radiator = [];
  return obj;
}
